.touchscreen-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding: 0;
}

.fullscreen-button {
  position: fixed;
  top: 10px;
  right: -7px;
  padding: 10px 20px;
  z-index: 1000;
  width: 128px !important;
  background: none;
  border: none;
  cursor: pointer;
}

.orientation-button {
  position: fixed;
  top: 10px;
  left: -7px;
  padding: 10px 20px;
  z-index: 1000;
  width: 128px;
  background: none;
  border: none;
  cursor: pointer;
}

.button-image {
  width: 50px;  /* Adjust size as needed */
  height: auto;
  filter: invert(100%) brightness(200%);
}

.grid {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 100%;
}

.cell {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
}

.untouched {
  background-color: red;
}

.touched {
  background-color: green;
}
