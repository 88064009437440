/* Chatbox.css */
.chatbox-container {
    border: 1px solid #ccc;
    height: 300px; /* Set a fixed height for scrolling */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Hide scrollbars if content is smaller */
    background-color: rgba(0, 0, 0, 0.452);
    min-height: fit-content;
  }
  
  .message-list {
    flex-grow: 1; /* Allow message list to take up available space */
    overflow-y: auto; /* Add vertical scrollbar if content overflows */
    padding: 10px;
  }
  
  .message {
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .alias {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .input-area {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  input[type="text"] {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  button {
    padding: 8px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
