.Forum {
  color: white;
}

.signup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.signupForm {
  max-width: 50vw;
  min-width: 50vw;
  padding: 1vh;
  background-color: rgba(0, 0, 0, 0.247);
}

.signupForm label {
  padding: 1vh;
}

.signupForm input {
  min-width: 21vw;
  max-width: 27vw;
  border: rgb(199, 169, 0) 2px solid;
  border-radius: 3px;
}

.signupForm hr {
  max-width: 27vw;
  margin-top: 0px;
  margin-bottom: 1vh;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(69, 81, 114, 0.75), rgba(0, 0, 0, 0));
}

.signupForm h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.signupForm h4 {
  margin-top: 0;
  margin-bottom: 0;
}

#signupErrorMessage {
  color: rgb(255, 0, 0);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.384);
}

.redBorder {
  border-color: rgb(255, 0, 0) !important;
  border-width: 4px !important;
  background-color: rgb(204, 204, 204) !important;
  border-style: outset !important;
}

button {
  margin: 2vh;
  background-color: rgba(174, 159, 255, 0.205);
  color: rgb(255, 238, 142);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.384);
  border: solid rgba(0, 0, 0, 0.397) 2px;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  color: rgb(236, 217, 108);
  background-color: rgba(174, 159, 255, 0.404);
  border: solid rgba(0, 0, 0, 0.527) 2px;
}