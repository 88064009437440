.App {
  text-align: center;
  color: white;
}

body {
  background-color: #282c34 !important;
}

.vId {
  color: rgba(250, 250, 250, 0.877) !important;
}

.title {
  font-family: Nabla, system-ui;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "EDPT" 100, "EHLT" 12;
  font-weight: 400;
  margin: 0;
  text-decoration: none;
}

.key,
.keyboard,
.main-tests {
  box-shadow: 3px 3px #00000080;
}

.keyboard {
  background-color: #4b5261;
  border: solid;
  border-color: rgba(15, 14, 19, .432);
  border-radius: 5px;
  border-width: 2px;
  color: #fff;
  margin-top: 8px !important;
  padding: 6px;
}

.key {
  background-color: #ec8e62;
  border: solid;
  border-color: hsla(0, 0%, 100%, .432);
  border-radius: 5px;
  border-width: 1px;
  color: #410404;
}

.key-success {
  background-color: #4e6844;
  border: solid;
  border-color: rgba(0, 0, 0, .438);
  border-radius: 5px;
  border-width: 1px;
  color: #90f1b1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-tests {
  background-color: #4b5261;
  border: solid;
  border-color: rgba(15, 14, 19, .432);
  border-radius: 5px;
  border-width: 2px;
  color: #fff;
  ;
  padding: 6px;
}

.volume-meter-container {
  padding-left: 25%;
}

.volume-icon {
  font-size: 5vw;
  /* Adjust the size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: transform 0.1s;
  /* Smooth transition for scaling */
}

.video-container {
  padding: 0;
  /* Remove any padding */
  display: flex;
  /* Use flexbox to align items */
  justify-content: center;
  /* Center the video horizontally */
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

video {
  max-width: 100%;
}

.switch-button {
  background-color: initial;
  border: none;
  cursor: pointer;
  font-size: 200%;
  margin: 0;
  outline: none;
  padding: 0;

}

.switch-button-image {
  filter: invert(100%) brightness(200%);
  height: auto;
  width: 32px;
}

.MouseClicker {
  position: fixed;
  bottom: 10px;
  right: -7px;
  padding: 10px 20px;
  z-index: 1000;
  width: 128px !important;
  background: none;
  border: none;
  cursor: pointer;
  user-select: none;
}

.Lightbulb {
  width: 64px;
}

.Lightbulb-On {
  filter: invert(100%) brightness(200%);
  transform: translateX(2px);
  width: 72px;
}

.Lightbulb-Off {
  filter: invert(0) brightness(0);
}

iframe,
video {
  border: solid;
  border-color: #fff0;
  border-radius: 16px;
}

iframe {
  -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(#0000), color-stop(77%, #0000), to(#ffffff80));
}

.Video-Reflect {
  border-bottom: #000;

  -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(#0000), color-stop(77%, #0000), to(#ffffff80));
}

.Footer {
  color: #afaeaece;
  font-family: Playwrite IN,serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

p{
  margin-bottom: 2px !important;
}

.audio-visualizer-container{
  background-color: #000000b9;
  border-radius: 16px;
  border: solid rgba(0, 0, 0, 0.747);
}