.battery {
    border: 3px solid #333;
    max-width: 50%;
    min-height: 32px;
    padding: 2px;
    border-radius: 4px;
    position: relative;
    margin: 15px 0;
    left: 64px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 1px solid #98ff53d0;
        border-radius: 2px;
    }

}

.battery-level {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 100%;
    border-right: solid rgba(255, 255, 255, 0.247);

    --s: 12px;
    /* control the size*/
    --c1: #6bfe58;
    --c2: #749e35;

    --_g: #0000 52%, var(--c1) 54% 57%, #0000 59%;
    background:
        radial-gradient(farthest-side at -33.33% 50%, var(--_g)) 0 calc(var(--s)/2),
        radial-gradient(farthest-side at 50% 133.33%, var(--_g)) calc(var(--s)/2) 0,
        radial-gradient(farthest-side at 133.33% 50%, var(--_g)),
        radial-gradient(farthest-side at 50% -33.33%, var(--_g)),
        var(--c2);
    background-size: calc(var(--s)/4.667) var(--s), var(--s) calc(var(--s)/4.667);

    animation: battery-level-animation 10s infinite linear;

    &.warn {
        background-color: #EFAF13;
    }

    &.charging {
        animation: battery-charge-animation 6s infinite linear;

        &:after {
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cg%3E%3C%2Fg%3E%20%3Cpath%20fill%3D%22%23e81309%22%20d%3D%22M17.927%2012l2.68-10.28c0.040-0.126%200.060-0.261%200.060-0.4%200-0.726-0.587-1.32-1.314-1.32-0.413%200-0.78%200.187-1.019%200.487l-13.38%2017.353c-0.18%200.227-0.287%200.513-0.287%200.827%200%200.733%200.6%201.333%201.333%201.333h8.073l-2.68%2010.28c-0.041%200.127-0.060%200.261-0.060%200.4%200.001%200.727%200.587%201.32%201.314%201.32%200.413%200%200.78-0.186%201.020-0.487l13.379-17.353c0.181-0.227%200.287-0.513%200.287-0.827%200-0.733-0.6-1.333-1.333-1.333h-8.073z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat;
            background-size: 48px;
            height: 48px;
            width: 48px;
            content: '';
            display: inline-block;
            transform: translateY(-12px);
            animation: battery-grow-animation 3s infinite linear;

        }
    }
}

@keyframes battery-level-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -100% 0;
    }
}

@keyframes battery-charge-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}

@keyframes battery-grow-animation {
    0% {
        transform: scale(1);
        transform: translateY(-12px);
    }

    50% {
        transform: scale(1.2);
        transform: translateY(-14px);
    }

    100% {
        transform: scale(1);
        transform: translateY(-12px);
    }
}

.BatteryInfo {

    color: grey;
    font-size: small;
    h6{
        color: white;
        margin-bottom: 0;
    }
}